$(document).ready(function() {
  /* =====  MOBILE MENU TOGGLE  ====== */
  $(".offCanvasToggler").click(function(event) {
    event.preventDefault();
    $(".off-canvas-nav").toggleClass("active");
    $(".fullscreen-overlay").toggleClass("active");
    $("html, body").toggleClass("no-scrolling");
  });

  const extraMargin = () => {
    const divToEdit = $(".extra-margin");
    const blueCta = $(".blue-cta");

    divToEdit.css("margin-bottom", blueCta.height());

    blueCta.css("margin-top", blueCta.height() * -1);
  };

  /*======  ACTIONS  ======*/

  /*----------  PRODUCT MENU HOVER  ----------*/

  $(".nav-item").hover(function() {
    if ($(this).hasClass("nav-producten")) {
      $(".product-menu").show();
    } else {
      $(".product-menu").hide();
    }
  });

  $(".product-menu").hover(
    function() {
      $(".product-menu").show();
    },
    function() {
      $(".product-menu").hide();
    }
  );

  /*======  INITIAL ACTIONS  ======*/

  /*----------  EXTRA MARGIN CALCULATION  ----------*/

  extraMargin();

  /*----------  FOOTABLE INIT  ----------*/

  jQuery(function($) {
    $(".table").footable();
  });
});
